import { z } from 'zod';

import { UserKycSchema } from '@/schemas/kyc/user-kyc-schema.ts';
import { UserRole } from '@/types/users/user-role-enum.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

import { BankAccountSchema } from './bank-account-schema';
import { TransferSchema } from './transfer-schema';
import { WalletSchema } from './wallet-schema';

const UserStatusSchema = z.nativeEnum(UserStatus);

const UserRoleSchema = z.nativeEnum(UserRole);

export const UserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  status: UserStatusSchema,
  emailVerified: z.boolean(),
  role: UserRoleSchema,
  lastLoginDate: z.string().nullable(),
  emailVerificationCodeGeneratedAt: z.string().datetime().nullable(),
  sources: z.array(z.string()),
  allowNewsletter: z.boolean(),
  allowPriceReports: z.boolean(),
  allowProductUpdates: z.boolean(),
  hasPassword: z.boolean(),
  bankAccounts: z.array(BankAccountSchema).optional(),
  wallets: z.array(WalletSchema).optional(),
  kyc: UserKycSchema.nullish(),
  transfers: z.array(TransferSchema).optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type User = z.infer<typeof UserSchema>;

<script setup lang="ts">
import { computed } from 'vue';

import KycVerticalStepper from '@/components/kyc/KycVerticalStepper.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { KycLegalForm } from '@/schemas/kyc/user-kyc-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { kycData } = useKycProcess(props.user);

const { closeDialog } = useKycDialog();

const isAllDone = computed(() => {
  return props.user.status === UserStatus.KYC_VERIFIED;
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <div class="w-fit">
      <LocaleSelector />
    </div>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-8 overflow-scroll px-2 py-4">
      <div class="mt-20">
        <h4 class="font-bold">{{ $t('dialog.kyc.thankYou.title') }}</h4>
      </div>
      <p class="mb-2 mt-2 text-sm font-medium text-primary-600">
        {{
          isAllDone ? $t('dialog.kyc.thankYou.messageAllDone') : $t('dialog.kyc.thankYou.message')
        }}
      </p>
      <KycVerticalStepper
        :type="kycData.data.kyc.legalForm as KycLegalForm"
        :all-done="isAllDone"
        :personal-done="true"
        :company-done="true"
      />
      <TButton class="mt-4" @click="closeDialog">{{ $t('common.close') }}</TButton>
    </div>
  </div>
</template>

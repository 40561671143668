import { cva, type VariantProps } from 'class-variance-authority';

export { default as TWaveLoader } from './TWaveLoader.vue';

export const waveLoaderVariants = cva('animate-wave rounded-full', {
  variants: {
    variant: {
      default: 'bg-primary-500',
    },
    size: {
      default: 'h-3 w-3',
      xs: 'h-1 w-1',
      sm: 'h-2 w-2',
      lg: 'h-4 w-4',
      xl: 'h-4 w-4',
      '2xl': 'h-6 w-6',
      '3xl': 'h-8 w-8',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export const waveLoaderContainerVariants = cva('flex items-center justify-center', {
  variants: {
    size: {
      default: 'gap-2',
      xs: 'gap-1',
      sm: 'gap-1',
      lg: 'gap-2',
      xl: 'gap-3',
      '2xl': 'gap-4',
      '3xl': 'gap-5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export type WaveLoaderVariants = VariantProps<typeof waveLoaderVariants>;
export type WaveLoaderContainerVariants = VariantProps<typeof waveLoaderContainerVariants>;

import ArrowDoubleIcon from '@/assets/imgs/icons/arrow-double.svg';
import ArrowDownIcon from '@/assets/imgs/icons/arrow-down.svg';
import ArrowLeftIcon from '@/assets/imgs/icons/arrow-left.svg';
import ArrowRightCircleIcon from '@/assets/imgs/icons/arrow-right-circle.svg';
import ArrowRightIcon from '@/assets/imgs/icons/arrow-right.svg';
import ArrowUpIcon from '@/assets/imgs/icons/arrow-up.svg';
import CheckCircleOutlineIcon from '@/assets/imgs/icons/check-circle-outline.svg';
import ClockIcon from '@/assets/imgs/icons/clock.svg';
import CogIcon from '@/assets/imgs/icons/cog.svg';
import CopyIcon from '@/assets/imgs/icons/copy.svg';
import CrossIcon from '@/assets/imgs/icons/cross.svg';
import DrawerIcon from '@/assets/imgs/icons/drawer.svg';
import EmptyPageIcon from '@/assets/imgs/icons/empty-page.svg';
import EyeFilledIcon from '@/assets/imgs/icons/eye-filled.svg';
import EyeOffFilledIcon from '@/assets/imgs/icons/eye-off-filled.svg';
import FactsheetIcon from '@/assets/imgs/icons/factsheet.svg';
import FolderIcon from '@/assets/imgs/icons/folder.svg';
import HexaWarningOutlineIcon from '@/assets/imgs/icons/hexa-warning-outline.svg';
import InfoEmptyIcon from '@/assets/imgs/icons/info-empty.svg';
import InfoFullIcon from '@/assets/imgs/icons/info-full.svg';
import LockIcon from '@/assets/imgs/icons/lock.svg';
import LogoutIcon from '@/assets/imgs/icons/logout.svg';
import MenuIcon from '@/assets/imgs/icons/menu.svg';
import MinusCircleIcon from '@/assets/imgs/icons/minus-circle.svg';
import NavArrowDownIcon from '@/assets/imgs/icons/nav-arrow-down.svg';
import NavArrowUpIcon from '@/assets/imgs/icons/nav-arrow-up.svg';
import PlusCircleIcon from '@/assets/imgs/icons/plus-circle.svg';
import RefreshIcon from '@/assets/imgs/icons/refresh.svg';
import SortArrowsIcon from '@/assets/imgs/icons/sort-arrows.svg';
import TrashIcon from '@/assets/imgs/icons/trash.svg';
import UploadIcon from '@/assets/imgs/icons/upload.svg';
import VerifiedBadgeIcon from '@/assets/imgs/icons/verified-badge.svg';
import WarningTriangleOutlineIcon from '@/assets/imgs/icons/warning-triangle-outline.svg';

export enum IconType {
  cross = 'cross',
  infoFull = 'info-full',
  warningTriangleOutline = 'warning-triangle-outline',
  arrowUp = 'arrow-up',
  arrowDown = 'arrow-down',
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  arrowDouble = 'arrow-double',
  navArrowDown = 'nav-arrow-down',
  navArrowUp = 'nav-arrow-up',
  infoEmpty = 'info-empty',
  lock = 'lock',
  menu = 'menu',
  checkCircleOutline = 'check-circle-outline',
  hexaWarningOutline = 'hexa-warning-outline',
  eyeFilled = 'eye-filled',
  eyeOffFilled = 'eye-off-filled',
  factsheet = 'factsheet',
  folder = 'folder',
  logout = 'logout',
  cog = 'cog',
  upload = 'upload',
  emptyPage = 'empty-page',
  clock = 'clock',
  copy = 'copy',
  sortArrows = 'sort-arrows',
  arrowRightCircle = 'arrow-right-circle',
  drawer = 'drawer',
  plusCircle = 'plus-circle',
  minusCircle = 'minus-circle',
  refresh = 'refresh',
  verifiedBadge = 'verified-badge',
  trash = 'trash',
}

export enum IconSize {
  xs = 'var(--icon-size-xs)',
  sm = 'var(--icon-size-sm)',
  md = 'var(--icon-size-md)',
  lg = 'var(--icon-size-lg)',
  xl = 'var(--icon-size-xl)',
  full = 'var(--icon-size-full)',
}

export const ICONS = {
  [IconType.cross]: CrossIcon,
  [IconType.infoFull]: InfoFullIcon,
  [IconType.warningTriangleOutline]: WarningTriangleOutlineIcon,
  [IconType.arrowUp]: ArrowUpIcon,
  [IconType.arrowDown]: ArrowDownIcon,
  [IconType.arrowLeft]: ArrowLeftIcon,
  [IconType.arrowRight]: ArrowRightIcon,
  [IconType.arrowDouble]: ArrowDoubleIcon,
  [IconType.navArrowDown]: NavArrowDownIcon,
  [IconType.navArrowUp]: NavArrowUpIcon,
  [IconType.infoEmpty]: InfoEmptyIcon,
  [IconType.lock]: LockIcon,
  [IconType.menu]: MenuIcon,
  [IconType.checkCircleOutline]: CheckCircleOutlineIcon,
  [IconType.hexaWarningOutline]: HexaWarningOutlineIcon,
  [IconType.eyeFilled]: EyeFilledIcon,
  [IconType.eyeOffFilled]: EyeOffFilledIcon,
  [IconType.factsheet]: FactsheetIcon,
  [IconType.folder]: FolderIcon,
  [IconType.logout]: LogoutIcon,
  [IconType.cog]: CogIcon,
  [IconType.clock]: ClockIcon,
  [IconType.copy]: CopyIcon,
  [IconType.upload]: UploadIcon,
  [IconType.emptyPage]: EmptyPageIcon,
  [IconType.sortArrows]: SortArrowsIcon,
  [IconType.arrowRightCircle]: ArrowRightCircleIcon,
  [IconType.drawer]: DrawerIcon,
  [IconType.plusCircle]: PlusCircleIcon,
  [IconType.minusCircle]: MinusCircleIcon,
  [IconType.refresh]: RefreshIcon,
  [IconType.verifiedBadge]: VerifiedBadgeIcon,
  [IconType.trash]: TrashIcon,
};

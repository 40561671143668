<script setup lang="ts">
import { computed, watch, type Component } from 'vue';

import KycDialogContentBankAccount from '@/components/kyc/KycDialogContentBankAccount.vue';
import KycDialogContentCompanyInfo from '@/components/kyc/KycDialogContentCompanyInfo.vue';
import KycDialogContentIdentity from '@/components/kyc/KycDialogContentIdentity.vue';
import KycDialogContentIntro from '@/components/kyc/KycDialogContentIntro.vue';
import KycDialogContentLegalForm from '@/components/kyc/KycDialogContentLegalForm.vue';
import KycDialogContentPersonalInfo from '@/components/kyc/KycDialogContentPersonalInfo.vue';
import KycDialogContentRegistrationDoc from '@/components/kyc/KycDialogContentRegistrationDoc.vue';
import KycDialogContentThankYou from '@/components/kyc/KycDialogContentThankYou.vue';
import KycDialogContentVerify from '@/components/kyc/KycDialogContentVerify.vue';
import { TButton } from '@/components/ui/button';
import { TDialog, TDialogClose, TDialogContent, TDialogTrigger } from '@/components/ui/dialog';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { useUseKycQuery } from '@/queries/kyc/use-user-kyc-query.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

withDefaults(
  defineProps<{
    triggerClass?: string;
    triggerCallback?: () => void;
  }>(),
  {
    triggerClass: '',
    triggerCallback: () => {},
  },
);

const stateComponents: Record<KycDialogState, Component> = {
  [KycDialogState.Intro]: KycDialogContentIntro,
  [KycDialogState.EmailVerification]: KycDialogContentVerify,
  [KycDialogState.LegalForm]: KycDialogContentLegalForm,
  [KycDialogState.PersonalInformation]: KycDialogContentPersonalInfo,
  [KycDialogState.IdentityDocuments]: KycDialogContentIdentity,
  [KycDialogState.CompanyInformation]: KycDialogContentCompanyInfo,
  [KycDialogState.RegistrationDocuments]: KycDialogContentRegistrationDoc,
  [KycDialogState.BankInformation]: KycDialogContentBankAccount,
  [KycDialogState.ThankYou]: KycDialogContentThankYou,
};

const { isAuthenticated } = useAuth();

const { isOpen, currentState, closeDialog, changeModalState } = useKycDialog();

const { data: user, isLoading: isUserLoading } = useUserMeQuery();

const hasPersonalData = computed(() => {
  if (!user.value) return false;

  return user.value.kyc?.firstName != null;
});

const hasCompanyData = computed(() => {
  if (!user.value) return false;

  return user.value.kyc?.companyName != null;
});

const hasBankAccount = computed(() => {
  if (!user.value) return false;

  const bankAccounts = user.value.bankAccounts;

  return bankAccounts && bankAccounts.length > 0;
});

const hasDocuments = computed(() => {
  if (!user.value) return false;

  const documents = user.value.kyc?.documents ?? [];

  return documents && documents.length > 0;
});

const getCurrentIndividualStep = (): KycDialogState => {
  if (hasPersonalData.value && !hasBankAccount.value) {
    return KycDialogState.BankInformation;
  } else if (hasBankAccount.value && !hasDocuments.value) {
    return KycDialogState.IdentityDocuments;
  } else if (hasDocuments.value) {
    return KycDialogState.ThankYou;
  }

  return KycDialogState.LegalForm;
};

const getCurrentCompanyStep = (): KycDialogState => {
  const isKycDone = user.value?.status === UserStatus.KYC_DONE;

  if (hasPersonalData.value && !hasDocuments.value) {
    return KycDialogState.IdentityDocuments;
  } else if (hasDocuments.value && !hasCompanyData.value) {
    return KycDialogState.CompanyInformation;
  } else if (hasCompanyData.value && !hasBankAccount.value) {
    return KycDialogState.BankInformation;
  } else if (hasBankAccount.value && !isKycDone) {
    return KycDialogState.RegistrationDocuments;
  }

  return KycDialogState.LegalForm;
};

const getDefaultState = () => {
  if (!user.value) return KycDialogState.Intro;

  const { mustSendCode, isCodeExpired, kycData: kycProcessData } = useKycProcess(user.value);

  if (user.value.status === UserStatus.KYC_DONE) {
    return KycDialogState.ThankYou;
  }

  if (kycProcessData.value.data.id !== '') {
    if (!hasPersonalData.value) {
      return KycDialogState.LegalForm;
    } else if (kycProcessData.value.data.kyc.legalForm === 'INDIVIDUAL') {
      return getCurrentIndividualStep();
    } else if (kycProcessData.value.data.kyc.legalForm === 'COMPANY') {
      return getCurrentCompanyStep();
    }
  }

  if (!mustSendCode.value) return KycDialogState.LegalForm;

  return isCodeExpired() ? KycDialogState.Intro : KycDialogState.EmailVerification;
};

const setDefaultState = (isDialogOpen: boolean): void => {
  if (isDialogOpen) {
    changeModalState(getDefaultState());
  }
};

const canGetKyc = computed(() => {
  const hasValidStatus =
    user.value?.status === UserStatus.KYC_PENDING || user.value?.status === UserStatus.KYC_REJECTED;
  return isOpen.value && hasValidStatus;
});

const { data: kycData, isLoading: isKycLoading } = useUseKycQuery(canGetKyc);

const allResourcesLoaded = computed(() => {
  if (isUserLoading.value) return false;
  return !(canGetKyc.value && isKycLoading.value) && isOpen.value;
});

const onAllResourcesLoaded = () => {
  useKycProcess(user.value as User, kycData.value);
  setDefaultState(true);
};

watch(allResourcesLoaded, (newValue) => {
  if (newValue) {
    onAllResourcesLoaded();
  }
});
</script>

<template>
  <TDialog v-if="isAuthenticated && user" v-model:open="isOpen">
    <TDialogTrigger as-child>
      <TButton variant="secondary" size="sm" :class="triggerClass" @click="triggerCallback">{{
        $t('dialog.kyc.button')
      }}</TButton>
    </TDialogTrigger>
    <TDialogContent
      class="h-full max-h-[700px] max-w-[1200px] grid-rows-[auto_minmax(0,1fr)_auto] bg-beige-100 p-0"
      @interact-outside="closeDialog"
    >
      <template #modal-close>
        <TDialogClose />
      </template>
      <div
        v-if="isUserLoading || isKycLoading"
        class="flex h-full w-full items-center justify-center"
      >
        <TWaveLoader />
      </div>
      <component :is="stateComponents[currentState]" v-else-if="isOpen" :user="user" />
    </TDialogContent>
  </TDialog>
</template>

<script setup lang="ts">
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { kycData } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const setLegalForm = (legalForm: string): void => {
  kycData.value.data.kyc.legalForm = legalForm;
  changeModalState(KycDialogState.PersonalInformation);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="flex w-full max-w-[460px] flex-col gap-8 px-2 py-4">
      <h4 class="font-bold">{{ $t('dialog.kyc.legalForm.title') }}</h4>
      <p>{{ $t('dialog.kyc.legalForm.description') }}</p>
      <div class="flex w-full gap-2">
        <TButton
          size="lg"
          variant="light-outline"
          class="flex-1"
          @click="setLegalForm('INDIVIDUAL')"
          >{{ $t('dialog.kyc.legalForm.individual') }}</TButton
        >
        <TButton
          size="lg"
          variant="light-outline"
          class="flex-1"
          @click="setLegalForm('COMPANY')"
          >{{ $t('dialog.kyc.legalForm.company') }}</TButton
        >
      </div>
    </div>
  </div>
</template>

import { z } from 'zod';

export const BankAccountSchema = z.object({
  id: z.string(),
  userId: z.string().uuid(),
  accountNumber: z.string(),
  bankName: z.string(),
  default: z.boolean(),
  internalReference: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type BankAccount = z.infer<typeof BankAccountSchema>;

<script setup lang="ts">
import { TIcon } from '@/components/ui/icon';

const emit = defineEmits(['click']);
</script>

<template>
  <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="emit('click')">
    <TIcon icon="arrowLeft" color="primary-500" size="sm" />
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import VerticalStepper from '@/components/VerticalStepper.vue';
import { VerticalStepperStep } from '@/types/vertical-stepper-step.ts';

const { t: $t } = useI18n();

const props = withDefaults(
  defineProps<{
    type: 'INDIVIDUAL' | 'COMPANY';
    personalDone: boolean;
    companyDone: boolean;
    allDone: boolean;
  }>(),
  {
    type: 'INDIVIDUAL',
    personalDone: false,
    companyDone: false,
    allDone: false,
  },
);

const steps = computed(() => {
  const stepsRaw: VerticalStepperStep[] = [
    {
      key: 'personalInformation',
      label: $t('dialog.kyc.stepper.personalInformation'),
      downConnectorClass: 'bg-success-500',
      dotClass: 'bg-success-500',
    },
  ];

  if (props.type === 'COMPANY') {
    stepsRaw.push({
      key: 'companyInformation',
      label: $t('dialog.kyc.stepper.companyInformation'),
      upConnectorClass: 'bg-success-500',
      downConnectorClass: 'bg-success-500',
      dotClass: 'bg-success-500',
    });
  }

  stepsRaw.push({
    key: 'verification',
    label: $t('dialog.kyc.stepper.verifying'),
    upConnectorClass: props.allDone ? 'bg-success-500' : 'bg-warning-500',
    downConnectorClass: props.allDone ? 'bg-success-500' : '',
    dotClass: props.allDone ? 'bg-success-500' : 'bg-warning-500',
  });

  stepsRaw.push({
    key: 'verified',
    upConnectorClass: props.allDone ? 'bg-success-500' : '',
    dotClass: props.allDone ? 'bg-success-500' : '',
    label: $t('dialog.kyc.stepper.verified'),
  });

  return stepsRaw;
});
</script>

<template>
  <VerticalStepper :steps="steps" />
</template>

import { cva, type VariantProps } from 'class-variance-authority';

export { default as TLink } from './TLink.vue';

export const linkVariants = cva('', {
  variants: {
    variant: {
      default:
        'text-primary-600 hover:text-primary-900 font-medium hover:font-semibold transition-all duration-300',
    },
    size: {
      default: 'rounded-lg text-ui-m',
      sm: 'text-ui-s',
      lg: 'text-ui-l',
      xl: 'text-ui-xl',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export type LinkVariants = VariantProps<typeof linkVariants>;

export enum KycDialogState {
  Intro = 'intro',
  EmailVerification = 'email-verification',
  LegalForm = 'legal-form',
  PersonalInformation = 'personal-information',
  IdentityDocuments = 'identity-documents',
  CompanyInformation = 'company-information',
  RegistrationDocuments = 'registration-documents',
  BankInformation = 'bank-information',
  ThankYou = 'thank-you',
}

<script setup lang="ts">
import { TooltipTrigger, type TooltipTriggerProps } from 'radix-vue';
import { inject } from 'vue';

const props = defineProps<TooltipTriggerProps>();

const { setOpen } = inject('tooltipTriggerProvide') as { setOpen: (value: boolean) => void };
</script>

<template>
  <TooltipTrigger v-bind="props" class="pointer-events-auto" @click="() => setOpen(true)">
    <slot />
  </TooltipTrigger>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useLocale } from '@/hooks/use-locale.ts';

const { availableLocales, locale } = useI18n();
const { changeLocale } = useLocale();

const handleLocaleChange = (newLocale: string): void => {
  changeLocale(newLocale);
};
</script>

<template>
  <Select :default-value="locale" @update:model-value="handleLocaleChange">
    <SelectTrigger
      class="!ring-offset-none focus:!ring-offset-none border-none bg-transparent !ring-0 focus:!outline-none focus:!ring-0"
      :autofocus="false"
    >
      <SelectValue />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem
          v-for="availableLocale in availableLocales"
          :key="availableLocale"
          :value="availableLocale"
        >
          {{ availableLocale.toUpperCase() }}
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </Select>
</template>

import queryStringHelper, { StringifiableRecord } from 'query-string';

export function queryString(query: StringifiableRecord): string | null {
  if (query === null) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  return queryStringHelper.stringify(query).toString();
}

<script setup lang="ts">
import { cn } from '@/lib/utils.ts';

import { waveLoaderVariants, WaveLoaderVariants, waveLoaderContainerVariants } from '.';

interface Props {
  variant?: WaveLoaderVariants['variant'];
  size?: WaveLoaderVariants['size'];
  class?: string;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  size: 'default',
  class: '',
});
</script>

<template>
  <div :class="cn(waveLoaderContainerVariants({ size }), '')">
    <div
      v-for="(_, index) in 3"
      :key="index"
      :class="cn(waveLoaderVariants({ variant, size }), props.class)"
      :style="{ animationDelay: `${index * 0.2}s` }"
    ></div>
  </div>
</template>

<style scoped>
@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.animate-wave {
  animation: wave 1.5s ease-in-out infinite;
}
</style>

<script setup lang="ts">
import { IconoirProvider } from '@iconoir/vue';
import { useHead } from '@unhead/vue';
import { computed, DefineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { Toaster } from '@/components/ui/sonner';
import { useLogoutInterceptor } from '@/hooks/use-logout-interceptor.ts';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

interface Layouts {
  [key: string]: DefineComponent;
}

const route = useRoute();

useLogoutInterceptor();

useHead({
  title: 'Homaio',
});

const layoutName = computed<string>(() => {
  return ((route.meta.layout as string) || 'default') + '-layout';
});

const layout = computed<DefineComponent>(() => {
  return layouts[layoutName.value] || DefaultLayout;
});

const layouts: Layouts = {
  'default-layout': DefaultLayout as DefineComponent,
  'empty-layout': EmptyLayout as DefineComponent,
};
</script>

<template>
  <IconoirProvider
    :icon-props="{
      color: 'currentColor',
      'stroke-width': 1.5,
      width: '1.14285714em',
      height: '1.14285714em',
    }"
  >
    <Toaster class="pointer-events-auto" rich-colors />
    <component :is="layout" />
  </IconoirProvider>
</template>

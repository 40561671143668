import { z } from 'zod';

const AddressAutocompleteResultsItemSchema = z.object({
  rawAddress: z.string(),
  address: z.string(),
  city: z.string(),
  postalCode: z.string(),
  country: z.string(),
});

export const AddressAutocompleteResultsSchema = z.object({
  results: z.array(AddressAutocompleteResultsItemSchema),
});

export type AddressAutocompleteResultsItem = z.infer<typeof AddressAutocompleteResultsItemSchema>;
export type AddressAutocompleteResults = z.infer<typeof AddressAutocompleteResultsSchema>;

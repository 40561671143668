<script setup lang="ts">
import { contentBlockSpacings, contentBlockPaddings } from '@/components/ui/content-block/index.ts';

const props = withDefaults(
  defineProps<{
    padding?: keyof typeof contentBlockPaddings;
    space?: keyof typeof contentBlockSpacings;
    hasSeparator?: boolean;
  }>(),
  {
    padding: 'default',
    space: 'default',
    hasSeparator: false,
  },
);
</script>

<template>
  <div
    v-bind="$attrs"
    class="w-full overflow-x-clip"
    :class="{
      [contentBlockPaddings[props.padding]]: true,
      [contentBlockSpacings[props.space]]: true,
      'border-b border-primary-400 pb-4': props.hasSeparator,
    }"
  >
    <slot />
  </div>
</template>

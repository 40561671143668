import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { User, UserSchema } from '@/schemas/users/user-schema.ts';

interface LoginVariables {
  email: string;
  password: string;
}

export function useLoginMutation(
  onSuccess?: (user: User) => void,
  onError?: (error: ApiError) => void,
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const { login, identifyUserOnVendorServices } = useAuth();

  return useMutation<User, ApiError, LoginVariables>({
    mutationFn: async ({ email, password }) => {
      const response = await api.post<User>('auth/login', { email, password });
      validateApiSchema(UserSchema, response.data);
      return response.data;
    },
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
      queryClient.setQueryData([API_QUERY_KEY_USER_ME], data);
      identifyUserOnVendorServices(data);

      await login();
      onSuccess?.(data);
    },
  });
}

import {
  NavigationGuardNext,
  RouteLocationNormalizedGeneric,
  RouteLocationNormalizedLoadedGeneric,
} from 'vue-router';

export function notFoundGuard(
  to: RouteLocationNormalizedGeneric,
  _from: RouteLocationNormalizedLoadedGeneric,
  next: NavigationGuardNext,
): void {
  if (to.matched.length === 0) {
    next({ name: 'NotFound' });
  } else {
    next();
  }
}

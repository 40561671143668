import { useMutation, UseMutationReturnType, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants';
import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';
import { CreateWithdrawalFormData } from '@/models/users/create-withdrawal.ts';

export function useCreateWithdrawal(
  onSuccess?: () => void,
  onError?: (error: ApiError) => void,
): UseMutationReturnType<void, Error, CreateWithdrawalFormData, unknown> {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateWithdrawalFormData>({
    mutationFn: async (form: CreateWithdrawalFormData) => {
      try {
        const response = await api.post<void>(`users/${form.userId}/transfers/WITHDRAW`, {
          bankAccountId: form.bankAccountId,
          amount: form.amount,
        });

        return response.data;
      } catch (error) {
        throw error as ApiError;
      }
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });

      onSuccess?.();
    },
  });
}

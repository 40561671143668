import { useMutation, UseMutationReturnType } from '@tanstack/vue-query';

import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';
import { CreateDepositFormData } from '@/models/users/create-deposit.ts';

export function useCreateDeposit(
  onSuccess?: () => void,
  onError?: (error: ApiError) => void,
): UseMutationReturnType<void, Error, CreateDepositFormData, unknown> {
  const api = useApi();

  return useMutation<void, Error, CreateDepositFormData>({
    mutationFn: async (form: CreateDepositFormData) => {
      try {
        const response = await api.post<void>(`users/${form.userId}/transfers/DEPOSIT`, {
          bankAccountId: form.bankAccountId,
        });

        return response.data;
      } catch (error) {
        throw error as ApiError;
      }
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });
}

<script setup lang="ts">
import { toast } from 'vue-sonner';

import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useSendVerifyEmailMutation } from '@/queries/auth/use-send-verify-email-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { closeDialog, changeModalState } = useKycDialog();

const { updateLastEmailCodeGeneratedAt } = useKycProcess(props.user);

const onSuccess = (): void => {
  updateLastEmailCodeGeneratedAt(new Date());
  changeModalState(KycDialogState.EmailVerification);
};

const onError = (error: ApiError): void => {
  toast.error(error.data.message);
};

const { mutate: sendVerifyEmailMutation, isPending: isSendVerifyEmailPending } =
  useSendVerifyEmailMutation(onSuccess, onError);

const procceedVerification = (): void => {
  sendVerifyEmailMutation();
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-2 overflow-scroll px-2 py-4">
      <h4 class="font-bold">{{ $t('dialog.kyc.intro.title') }}</h4>
      <div class="mb-6 mt-4 flex flex-col gap-4 text-sm font-medium text-primary-600">
        <p>
          {{ $t('dialog.kyc.intro.description1') }}
        </p>
        <ul class="flex list-inside list-disc flex-col gap-2 px-2">
          <li>
            {{ $t('dialog.kyc.intro.description2') }}
          </li>
          <li>
            {{ $t('dialog.kyc.intro.description3') }}
          </li>
          <li>
            {{ $t('dialog.kyc.intro.description4') }}
          </li>
        </ul>
        <p>
          {{ $t('dialog.kyc.intro.description5') }}
        </p>
      </div>
      <TButton @click="procceedVerification">
        <p v-if="!isSendVerifyEmailPending">{{ $t('common.continue') }}</p>
        <TWaveLoader v-else size="sm" class="bg-white" />
      </TButton>
    </div>
  </div>
</template>

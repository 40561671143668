import { type VariantProps, cva } from 'class-variance-authority';

export { default as TSheet } from './TSheet.vue';
export { default as TSheetTrigger } from './TSheetTrigger.vue';
export { default as TSheetClose } from './TSheetClose.vue';
export { default as TSheetContent } from './TSheetContent.vue';
export { default as TSheetHeader } from './TSheetHeader.vue';
export { default as TSheetTitle } from './TSheetTitle.vue';
export { default as TSheetDescription } from './TSheetDescription.vue';
export { default as TSheetFooter } from './TSheetFooter.vue';

export const sheetVariants = cva(
  'fixed z-50 gap-4 bg-white p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 dark:bg-slate-950',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
);

export type SheetVariants = VariantProps<typeof sheetVariants>;

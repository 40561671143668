import { Updater } from '@tanstack/vue-table';
import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { type Ref } from 'vue';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-base',
        'text-h1',
        'text-h2',
        'text-h3',
        'text-h4',
        'text-h5',
        'text-body-l',
        'text-body-m',
        'text-body-s',
        'text-body-xs',
        'text-data-h1',
        'text-data-h2',
        'text-data-h3',
        'text-data-h4',
        'text-ui-xl',
        'text-ui-l',
        'text-ui-m',
        'text-ui-s',
      ],
      'font-family': ['font-sans', 'font-manrope'],
    },
    theme: {
      colors: ['primary', 'secondary', 'invert', 'info', 'success', 'warning', 'danger'],
    },
  },
});

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

// XXX: Is this function still necessary ?
export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  // eslint-disable-next-line no-param-reassign
  ref.value = typeof updaterOrValue === 'function' ? updaterOrValue(ref.value) : updaterOrValue;
}

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { TIcon } from '@/components/ui/icon';
import TInput from '@/components/ui/input/TInput.vue';

const { t: $t } = useI18n();

const props = withDefaults(defineProps<{ placeholder?: string | null }>(), {
  placeholder: null,
});

const showPassword = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div class="relative inline-block w-full">
    <TInput
      v-bind="$attrs"
      ref="inputRef"
      class="pr-10"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="props.placeholder ?? $t('component.passwordInput.placeholder')"
    />
    <div class="absolute inset-y-0 right-0 flex items-center pr-3">
      <TIcon
        v-if="!showPassword"
        class="cursor-pointer"
        color="primary-600"
        size="xs"
        icon="eyeFilled"
        @mousedown.prevent
        @click="toggleShowPassword"
      />
      <TIcon
        v-else
        class="cursor-pointer"
        color="primary-600"
        size="xs"
        icon="eyeOffFilled"
        @mousedown.prevent
        @click="toggleShowPassword"
      />
    </div>
  </div>
</template>

export class StorageService {
  private static _instance?: StorageService;

  private constructor() {}

  static getInstance(): StorageService {
    if (!StorageService._instance) {
      StorageService._instance = new StorageService();
    }

    return StorageService._instance;
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

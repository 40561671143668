<template>
  <div class="icon-container" :style="iconStyle">
    <component :is="iconSvg" class="svg-inline" />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

import { ICONS, IconSize, IconType } from '@/constants/icons-constants.ts';

const props = defineProps<{
  icon: keyof typeof IconType;
  color?: string;
  hoverColor?: string;
  size?: keyof typeof IconSize;
}>();

const resolveColor = (color: string) => {
  if (color.startsWith('#')) {
    return color;
  }

  return `var(--color-${color})`;
};

const iconSvg = computed(() => {
  return ICONS[IconType[props.icon]];
});

const colorStyle = computed(() => {
  return props.color ? `--icon-color: ${resolveColor(props.color)};` : '';
});

const hoverColorStyle = computed(() => {
  return `--icon-hover-color: ${resolveColor(props.hoverColor ? props.hoverColor : props.color || '')};`;
});

const sizeStyle = computed(() => {
  return props.size ? `--icon-size: ${IconSize[props.size]};` : '';
});

const iconStyle = computed(() => {
  return [colorStyle.value, sizeStyle.value, hoverColorStyle.value].join('');
});
</script>

<style lang="scss" scoped>
.icon-container {
  --icon-color: inherit;
  --icon-hover-color: inherit;
  --icon-size: var(--icon-size-md, inherit);
  width: var(--icon-size, inherit);
  height: var(--icon-size, inherit);
  overflow: hidden;

  .svg-inline {
    display: inline-block;
    font-size: inherit;
    float: left;
    color: var(--icon-color, inherit);
    width: var(--icon-size, inherit);
    height: var(--icon-size, inherit);

    &:hover {
      color: var(--icon-hover-color, inherit);
    }
  }
}
</style>

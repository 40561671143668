export const getCurrencyFormatter = (
  currency: string,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2,
) => {
  // @NOTE: force to FR-fr cause we want currency sign as suffix. Might need rework if we want to support other locales
  return new Intl.NumberFormat('FR-fr', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const formatCurrency = (value: number, currency: string = 'EUR') => {
  return getCurrencyFormatter(currency).format(value).replace(',', '.');
};

export const getNumberFormatter = (digits: number = 2) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const getPercentageFormatter = () => {
  return new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function nFormatter(num: number, digits: number) {
  return Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(num);
}

export function parseAmount(amount: string) {
  const example = Intl.NumberFormat().format(1.1);
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
  const cleaned = amount.replace(cleanPattern, '');
  const normalized = cleaned.replace(example.charAt(1), '.');
  const amountParsed = parseFloat(normalized);

  if (isNaN(amountParsed)) return 0;
  else return amountParsed;
}

export function getNumberSign(num: number): '-' | '+' | '' {
  return num > 0 ? '+' : num < 0 ? '-' : '';
}

export function stripMinus(value: string): string {
  return value.replace('-', '');
}

export function formatValueChange(
  value: number,
  options?: { prefix?: string; nbDecimal?: number; suffix?: string },
): string {
  const sign = getNumberSign(value);
  const formattedValue = value.toFixed(options?.nbDecimal ?? 2);

  return `${sign}${options?.prefix ?? ''}${stripMinus(formattedValue.toString())}${options?.suffix ?? ''}`;
}

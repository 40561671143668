<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { toast } from 'vue-sonner';

import BankAccountCard from '@/components/bank-accounts/BankAccountCard.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { TWaveLoader } from '@/components/ui/wave-loader';
import VerticalStepper from '@/components/VerticalStepper.vue';
import { useWithdrawCalculation } from '@/hooks/invest/use-withdraw-calculation';
import { useWithdrawDialog } from '@/hooks/invest/use-withdraw-dialog';
import { formatCurrency } from '@/lib/number-helpers.ts';
import { CreateWithdrawalFormData } from '@/models/users/create-withdrawal';
import { useCreateWithdrawal } from '@/queries/users/use-create-user-withdrawal';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { WithdrawDialogState } from '@/types/invest/withdraw-dialog-state-enum';

import WithdrawDialogBackBtn from './WithdrawDialogBackBtn.vue';

const router = useRouter();

const { data: user } = useUserMeQuery();

const { t: $t } = useI18n();

const { steps, changeModalState, closeDialog } = useWithdrawDialog();
const { amount } = useWithdrawCalculation();
const { mutateAsync: createWithdrawal, isPending: isCreatingWithdrawal } = useCreateWithdrawal();

const back = () => {
  changeModalState(WithdrawDialogState.Creation);
};

const walletEUR = computed(() => {
  return user.value?.wallets?.[0]?.value || 0;
});

const handleSubmit = async () => {
  const userId = user.value!.id;
  const bankAccountId = user.value!.bankAccounts?.[0].id ?? '';
  // create withdraw
  const form: CreateWithdrawalFormData = {
    userId,
    bankAccountId,
    amount: amount.value ?? 0,
  };

  try {
    await createWithdrawal(form);
    amount.value = null;
    closeDialog();
    toast.success($t('component.withdraw.success'));
  } catch (error) {
    console.error(error);
    toast.error($t('component.withdraw.error'));
  } finally {
    await router.push('/portfolio');
  }
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <WithdrawDialogBackBtn @click="back" />
    <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
      <TIcon icon="cross" color="primary-600" size="md" />
    </p>
  </TDialogHeader>
  <div class="no-scrollbar mt-8 flex justify-center overflow-scroll">
    <div>
      <h4 class="font-manrope text-h4 font-bold text-primary-900">
        {{ $t('common.transactionType.withdraw') }}
      </h4>
      <div class="relative mb-8 mt-10 flex w-full flex-col gap-2">
        <div class="flex w-full flex-col rounded-lg border border-primary-300 bg-white p-6">
          <p class="mb-4 font-medium text-primary-600">
            {{ $t('layout.navBar.userDropdown.withdraw') }}
          </p>
          <div class="align-center mb-6 flex w-full items-center justify-start gap-4">
            <span class="text-data-h2 font-semibold">{{ formatCurrency(amount!, 'EUR') }}</span>
          </div>

          <div class="flex w-full items-center justify-between gap-2 rounded-xl bg-beige-100 p-4">
            <div class="flex gap-4">
              <div
                class="flex h-[52px] w-[52px] items-center justify-center rounded-xl bg-beige-400"
              >
                <TIcon icon="drawer" color="primary-500" size="md" />
              </div>
              <div class="flex flex-col items-start justify-start font-medium">
                <span class="text-left text-lg text-primary-800">
                  {{ $t('component.withdraw.from') }}</span
                >
                <span class="text-primary-600"> {{ $t('component.buy.eurWallet') }}</span>
              </div>
            </div>
            <div class="text-sm font-semibold text-primary-700">
              <p class="text-lg font-medium text-primary-800">
                {{ formatCurrency(walletEUR, 'EUR') }}
              </p>
              <p class="text-right text-primary-600">
                {{ $t('component.buy.remaining') }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="absolute left-1/2 top-[55%] flex h-10 w-10 -translate-x-1/2 items-center justify-center rounded-md bg-primary-700"
        >
          <TIcon icon="arrowDown" color="white" size="md" />
        </div>
        <BankAccountCard>
          <template #title>
            <span class="text-primary-600">{{ $t('component.withdraw.to') }}</span>
          </template>
        </BankAccountCard>
      </div>
      <div class="flex flex-col gap-6">
        <p class="font-medium text-primary-800">
          {{ $t('component.withdraw.processDetails') }}
        </p>
        <VerticalStepper :steps="steps" />
      </div>
      <TButton
        class="my-10"
        size="fluid-default"
        :disabled="isCreatingWithdrawal"
        @click="handleSubmit"
      >
        <TWaveLoader v-if="isCreatingWithdrawal" size="sm" class="bg-white" />
        <p v-else>{{ $t('component.withdraw.confirm') }}</p>
      </TButton>
    </div>
  </div>
</template>

import { useQueryClient } from '@tanstack/vue-query';
import { nextTick, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';

import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { useAnalytics } from '@/hooks/use-analytics.ts';
import { setSentryUser } from '@/lib/sentry-helpers.ts';
import { User } from '@/schemas/users/user-schema.ts';

const isAuthenticated: Ref<boolean> = ref(true);

export function useAuth() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const analytics = useAnalytics();

  const identifyUserOnVendorServices = (user: User) => {
    analytics.identify(user.id, user);
    setSentryUser(user);
  };

  const setAuthState = async (state: boolean) => {
    const previousState = isAuthenticated.value;
    isAuthenticated.value = state;
    if (previousState && !state) {
      const { resetKycData } = useKycProcess();
      await nextTick();
      resetKycData();
      await queryClient.resetQueries({ predicate: (query) => query.meta?.authRequired === true });
    }
  };

  const logoutAction = async () => {
    await setAuthState(false);
    const currentRoute = router.currentRoute.value;

    if (currentRoute.meta.authRequired === true) {
      await router.push({ name: 'AssetPerformance' });
    }
  };

  return {
    isAuthenticated,
    identifyUserOnVendorServices,
    login: async () => setAuthState(true),
    logout: async () => logoutAction(),
  };
}

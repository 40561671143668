import { useMutation } from '@tanstack/vue-query';

import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';

type ResetPasswordVariables = { email: string };

export function useResetPasswordMutation(
  onSuccess?: () => void,
  onError?: (error: ApiError) => void,
) {
  const api = useApi();

  return useMutation<void, ApiError, ResetPasswordVariables>({
    mutationFn: async ({ email }: ResetPasswordVariables) => {
      const response = await api.put<void>('/auth/password/reset', {
        email,
      });

      return response.data;
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });
}

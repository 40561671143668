import { inject } from 'vue';

import { DI_ANALYTICS_SERVICE } from '@/constants/injection-constants.ts';
import AnalyticsService from '@/services/analytics/analytics-service.ts';

export function useAnalytics(): AnalyticsService {
  const analytics = inject(DI_ANALYTICS_SERVICE);

  if (!analytics) {
    throw new Error('FATAL ERROR: Analytics service is not provided.');
  }

  return analytics;
}

import { UserKyc } from '@/schemas/kyc/user-kyc-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';

type KycProcessDataType = {
  id: string;
  isEmailVerified: boolean;
  lastEmailCodeGeneratedAt: Date | null;
  email: string;
  form: 'INDIVIDUAL' | 'COMPANY';
  kyc: {
    userId: string;
    firstName: string;
    lastName: string;
    legalForm: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    countryOfResidence: string;
    preferredLanguage: string;
    birthDate: string;
    phoneCode: string;
    phoneNumber: string;
  };
  companyKyc: {
    companyName: string | null;
    companyAddress: string | null;
    companyPostalCode: string | null;
    companyCity: string | null;
    companyCountry: string | null;
    companyRole: string | null;
    companyRegistrationNumber: string | null;
  };
  bankAccount: {
    id: string | null;
    bankName: string;
    accountNumber: string;
    default: boolean;
  };
  identityDocuments: {
    front: {
      kycId: string;
      filename: string;
      expirationDate: number;
      type: 'PASSPORT' | 'ID_CARD' | 'COMPANY_REGISTRATION';
    };
    back?: {
      kycId: string;
      filename: string;
      expirationDate: number;
      type: 'PASSPORT' | 'ID_CARD' | 'COMPANY_REGISTRATION';
    };
  };
};

export class KycProcessData {
  data: KycProcessDataType;

  constructor(data: KycProcessDataType) {
    this.data = data;
  }

  static fromDefault(): KycProcessData {
    return new KycProcessData({
      id: '',
      isEmailVerified: false,
      lastEmailCodeGeneratedAt: null,
      email: '',
      form: 'INDIVIDUAL',
      kyc: {
        userId: '',
        firstName: '',
        lastName: '',
        legalForm: 'INDIVIDUAL',
        address: '',
        postalCode: '',
        city: '',
        country: '',
        countryOfResidence: '',
        preferredLanguage: '',
        birthDate: '',
        phoneCode: '',
        phoneNumber: '',
      },
      companyKyc: {
        companyName: null,
        companyAddress: null,
        companyPostalCode: null,
        companyCity: null,
        companyCountry: null,
        companyRole: null,
        companyRegistrationNumber: null,
      },
      bankAccount: {
        id: null,
        bankName: '',
        accountNumber: '',
        default: true,
      },
      identityDocuments: {
        front: {
          kycId: '',
          filename: '',
          expirationDate: 0,
          type: 'ID_CARD',
        },
        back: {
          kycId: '',
          filename: '',
          expirationDate: 0,
          type: 'ID_CARD',
        },
      },
    });
  }

  static fromUser(user: User): KycProcessData {
    return new KycProcessData({
      id: '',
      isEmailVerified: user.emailVerified,
      lastEmailCodeGeneratedAt: user.emailVerificationCodeGeneratedAt
        ? new Date(user.emailVerificationCodeGeneratedAt)
        : null,
      email: user.email,
      form: 'INDIVIDUAL',
      kyc: {
        userId: user.id,
        firstName: '',
        lastName: '',
        legalForm: '',
        address: '',
        postalCode: '',
        city: '',
        country: '',
        countryOfResidence: '',
        preferredLanguage: '',
        birthDate: '',
        phoneCode: '',
        phoneNumber: '',
      },
      bankAccount: {
        id: user.bankAccounts?.[0]?.id ?? null,
        bankName: user.bankAccounts?.[0]?.bankName ?? '',
        accountNumber: user.bankAccounts?.[0]?.accountNumber ?? '',
        default: user.bankAccounts?.[0]?.default ?? true,
      },
      companyKyc: {
        companyName: null,
        companyAddress: null,
        companyPostalCode: null,
        companyCity: null,
        companyCountry: null,
        companyRole: null,
        companyRegistrationNumber: null,
      },
      identityDocuments: {
        front: {
          kycId: '',
          filename: '',
          expirationDate: 0,
          type: 'ID_CARD',
        },
      },
    });
  }

  static fromUserAndKyc(user: User, kyc: UserKyc): KycProcessData {
    return new KycProcessData({
      id: kyc.id,
      isEmailVerified: user.emailVerified,
      lastEmailCodeGeneratedAt: user.emailVerificationCodeGeneratedAt
        ? new Date(user.emailVerificationCodeGeneratedAt)
        : null,
      email: user.email,
      form: 'INDIVIDUAL',
      kyc: {
        userId: user.id,
        firstName: kyc.firstName,
        lastName: kyc.lastName,
        legalForm: kyc.legalForm,
        address: kyc.address,
        postalCode: kyc.postalCode,
        city: kyc.city,
        country: kyc.country,
        countryOfResidence: kyc.countryOfResidence,
        preferredLanguage: kyc.preferredLanguage,
        birthDate: kyc.birthDate,
        phoneCode: kyc.phoneCode,
        phoneNumber: kyc.phoneNumber,
      },
      bankAccount: {
        id: user.bankAccounts?.[0]?.id ?? null,
        bankName: user.bankAccounts?.[0]?.bankName ?? '',
        accountNumber: user.bankAccounts?.[0]?.accountNumber ?? '',
        default: user.bankAccounts?.[0]?.default ?? true,
      },
      companyKyc: {
        companyName: kyc.companyName,
        companyAddress: kyc.companyAddress,
        companyPostalCode: kyc.companyPostalCode,
        companyCity: kyc.companyCity,
        companyCountry: kyc.companyCountry,
        companyRole: kyc.companyRole,
        companyRegistrationNumber: kyc.companyRegistrationNumber,
      },
      identityDocuments: {
        front: {
          kycId: '',
          filename: '',
          expirationDate: 0,
          type: 'ID_CARD',
        },
      },
    });
  }
}

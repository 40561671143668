<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { z } from 'zod';

import KycDialogBackBtn from '@/components/kyc/KycDialogBackBtn.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TCheckbox } from '@/components/ui/checkbox';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import TInput from '@/components/ui/input/TInput.vue';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useCreateUserBankAccountMutation } from '@/queries/users/use-create-user-bank-account-mutation.ts';
import { useUpdateUserBankAccountMutation } from '@/queries/users/use-update-user-bank-account-mutation.ts';
import { BankAccount } from '@/schemas/users/bank-account-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { t: $t } = useI18n();

const zodSchema = z.object({
  bankName: z.string(),
  accountNumber: z.string(),
  confirmation: z.boolean(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, setFieldValue, meta, values } = useForm({
  validationSchema: formSchema,
});

setFieldValue('confirmation', false);

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(
  () => !isFormValid.value || isSubmitting.value || !values.confirmation,
);

const errorMessage = ref('');

const { kycData } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const goBack = (): void => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    changeModalState(KycDialogState.PersonalInformation);
  } else {
    changeModalState(KycDialogState.CompanyInformation);
  }
};

const goNext = (): void => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    changeModalState(KycDialogState.IdentityDocuments);
  } else {
    changeModalState(KycDialogState.RegistrationDocuments);
  }
};

const stepTile = computed(() => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    return $t('dialog.kyc.bankInformation.title');
  }

  return $t('dialog.kyc.bankInformation.titleCompany');
});

const legalLabel = computed(() => {
  if (kycData.value.data.kyc.legalForm === 'INDIVIDUAL') {
    return $t('dialog.kyc.bankInformation.confirmation');
  }

  return $t('dialog.kyc.bankInformation.confirmationCompany', {
    companyName: kycData.value.data.companyKyc.companyName,
  });
});

const onSubmit = handleSubmit(async (formValues) => {
  const userId = kycData.value.data.kyc.userId;
  const bankAccountId = kycData.value.data.bankAccount.id as string;
  const form = {
    bankName: formValues.bankName,
    accountNumber: formValues.accountNumber,
  };

  try {
    const bankAccount = hasAlreadyCreatedBankAccount.value
      ? await updateBankAccount({ bankAccountId, userId, default: true, ...form })
      : await createBankAccount({ userId, ...form });

    updateBankAccountData(bankAccount);
    goNext();
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.data?.data?.accountNumber) {
        errorMessage.value = error.data?.data?.accountNumber[0];
      } else {
        errorMessage.value = error.data.message;
      }
    }
  }
});

const hasAlreadyCreatedBankAccount = computed(() => {
  return kycData.value.data.bankAccount.id !== null;
});

const prefillForm = (): void => {
  setFieldValue('bankName', kycData.value.data.bankAccount.bankName);
  setFieldValue('accountNumber', kycData.value.data.bankAccount.accountNumber);
};

onMounted(() => {
  if (hasAlreadyCreatedBankAccount.value) {
    prefillForm();
  }
});

const updateBankAccountData = (bankAccount: BankAccount) => {
  kycData.value.data.bankAccount.id = bankAccount.id;
  kycData.value.data.bankAccount.accountNumber = bankAccount.accountNumber;
  kycData.value.data.bankAccount.bankName = bankAccount.bankName;
  kycData.value.data.bankAccount.default = bankAccount.default;
};

const { mutateAsync: updateBankAccount, isPending: isUpdateBankAccountPending } =
  useUpdateUserBankAccountMutation();

const { mutateAsync: createBankAccount, isPending: isCreateBankAccountPending } =
  useCreateUserBankAccountMutation();

watch(values, (_) => {
  errorMessage.value = '';
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-8 overflow-scroll px-2 py-4">
      <div class="mt-20">
        <KycDialogBackBtn class="w-fit" @click="goBack" />
        <h4 class="font-bold">{{ stepTile }}</h4>
      </div>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="bankName">
          <FormItem>
            <FormControl>
              <TInput placeholder="Bank name" v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="accountNumber">
          <FormItem>
            <FormControl>
              <TInput placeholder="SWIFT or IBAN" v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <p v-if="errorMessage" class="my-2 text-sm text-danger-500">{{ errorMessage }}</p>
        <p class="text-sm font-medium text-primary-500">
          {{ $t('dialog.kyc.bankInformation.notice') }}
        </p>
        <div class="flex gap-2">
          <FormField v-slot="{ value, handleChange }" type="checkbox" name="confirmation">
            <FormItem class="flex flex-row items-start justify-start gap-2">
              <FormControl>
                <TCheckbox
                  class="mt-[2px] border-primary-600"
                  :checked="value"
                  @update:checked="handleChange"
                />
              </FormControl>
              <FormLabel class="!mt-0 text-sm font-medium text-primary-700">
                {{ legalLabel }}
              </FormLabel>
              <div>
                <FormMessage />
              </div>
            </FormItem>
          </FormField>
        </div>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader
            v-if="isCreateBankAccountPending || isUpdateBankAccountPending"
            size="sm"
            class="bg-white"
          />
          <p v-else>{{ $t('common.next') }}</p>
        </TButton>
      </form>
    </div>
  </div>
</template>

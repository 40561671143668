import { ref } from 'vue';

const amount = ref<number | null>(0);

export function useWithdrawCalculation() {
  const debounceAmount = ref<number>(0);

  const allowedKeys = (event: KeyboardEvent) => {
    const allowedChars = /[0-9,.]/;
    const value = (event.target as HTMLInputElement).innerText;
    if (
      (!allowedChars.test(event.key) && !['Backspace', 'Delete'].includes(event.key)) ||
      value.split('.')[0].length > 6
    ) {
      event.preventDefault();
    }
  };

  const debouncedUpdateAmount = (value: number, elem: HTMLInputElement) => {
    debounceAmount.value = window.setTimeout(() => {
      amount.value = value;
      elem.blur();
    }, 1000);
  };

  const handleAmountChange = (event: Event) => {
    const elem = event.target as HTMLInputElement;
    window.clearTimeout(debounceAmount.value);
    const value = elem.innerText.replace(/€|\s/g, '');

    if (value === '') {
      return;
    }

    debouncedUpdateAmount(parseFloat(value), elem);
  };

  const handleAmountFocus = () => {
    if (amount.value === 0) amount.value = null;
  };

  return {
    amount,
    handleAmountChange,
    handleAmountFocus,
    allowedKeys,
  };
}

<script setup lang="ts">
import { type Component } from 'vue';

import AuthDialogLoginContent from '@/components/auth/AuthDialogLoginContent.vue';
import AuthDialogRegisterContent from '@/components/auth/AuthDialogRegisterContent.vue';
import { TDialog, TDialogClose, TDialogContent } from '@/components/ui/dialog';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';

const stateComponents: Record<AuthDialogState, Component> = {
  [AuthDialogState.Login]: AuthDialogLoginContent,
  [AuthDialogState.Register]: AuthDialogRegisterContent,
};

const { isOpen, currentState, closeDialog } = useAuthDialog();
</script>

<template>
  <TDialog :open="isOpen">
    <TDialogContent
      class="h-full max-h-[700px] max-w-[1200px] grid-rows-[auto_minmax(0,1fr)_auto] bg-beige-100 p-0"
      @interact-outside="closeDialog"
    >
      <template #modal-close>
        <TDialogClose />
      </template>
      <component :is="stateComponents[currentState]" />
    </TDialogContent>
  </TDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{ canEdit?: boolean; flat?: boolean }>(), {
  canEdit: false,
  flat: false,
});

const emits = defineEmits<{
  (e: 'edit'): void;
}>();

import { useUserMeQuery } from '@/queries/users/use-users-me-query';

const { data: user } = useUserMeQuery();

const bankAccountInformation = computed(() => {
  if (!user.value?.bankAccounts?.length) return;

  const bankAccount = user.value.bankAccounts[0];

  const owner =
    user.value.kyc?.legalForm === 'INDIVIDUAL'
      ? `${user.value.kyc?.firstName} ${user.value.kyc?.lastName}`
      : user.value.kyc?.companyName;

  return {
    owner,
    bankName: bankAccount.bankName,
    accountNumber: bankAccount.accountNumber,
  };
});
</script>

<template>
  <div
    class="flex flex-col gap-6 text-body-m text-primary-500"
    :class="{
      'rounded-xl border border-primary-400 bg-white p-4': !props.flat,
      'w-full p-0': props.flat,
    }"
  >
    <div class="flex items-center justify-between gap-2">
      <slot name="title" />
      <RouterLink
        v-if="props.canEdit"
        to="/settings"
        class="cursor-pointer text-primary-700 underline underline-offset-2"
        @click="emits('edit')"
      >
        {{ $t('common.modify') }}
      </RouterLink>
    </div>
    <div>
      <div>{{ bankAccountInformation?.owner }}</div>
      <div>{{ bankAccountInformation?.bankName }}</div>
      <div>{{ bankAccountInformation?.accountNumber }}</div>
    </div>
  </div>
</template>

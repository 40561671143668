<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useLocale } from '@/hooks/use-locale.ts';

const { availableLocales, locale } = useI18n();
const { changeLocale } = useLocale();

const handleLocaleChange = (newLocale: string): void => {
  changeLocale(newLocale);
};
</script>

<template>
  <div class="flex items-center justify-center gap-6">
    <p
      v-for="availableLocale in availableLocales"
      :key="availableLocale"
      class="cursor-pointer font-medium"
      :class="{
        'text-primary-800': availableLocale === locale,
        'text-primary-600': availableLocale !== locale,
      }"
      @click="handleLocaleChange(availableLocale)"
    >
      {{ availableLocale.toUpperCase() }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { useDepositDialog } from '@/hooks/invest/use-deposit-dialog';
import { DepositDialogState } from '@/types/invest/deposit-dialog-state-enum';

const { closeDialog, changeModalState } = useDepositDialog();

const proceed = (): void => {
  changeModalState(DepositDialogState.Informations);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-end px-6 py-4 pb-0">
    <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
      <TIcon icon="cross" color="primary-600" size="md" />
    </p>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="flex flex-col gap-2 px-2 py-4 md:w-[460px]">
      <h4 class="mb-10 font-bold">{{ $t('dialog.deposit.intro.title') }}</h4>
      <div
        class="mb-6 flex items-start gap-4 rounded-lg border border-danger-200 bg-danger-100 p-4"
      >
        <TIcon icon="hexaWarningOutline" color="danger-600" size="md" />
        <div class="flex flex-1 flex-col gap-3 font-medium text-danger-600">
          <p class="text-sm font-semibold">{{ $t('dialog.deposit.intro.calloutTitle') }}</p>
          <div class="break-normal text-justify text-sm opacity-60">
            <p v-html="$t('dialog.deposit.intro.calloutDescription1')" />
            <br />
            <p>
              {{ $t('dialog.deposit.intro.calloutDescription2') }}
            </p>
            <br />
            <p>
              {{ $t('dialog.deposit.intro.calloutDescription3') }}
            </p>
          </div>
        </div>
      </div>
      <TButton @click="proceed"> {{ $t('common.continue') }} </TButton>
    </div>
  </div>
</template>

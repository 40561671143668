import { ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import { UserPreferencesService } from '@/services/user-preferences-service.ts';

export function useLocale() {
  const i18n = useI18n();
  const userPreferences = UserPreferencesService.getInstance();
  const currentLocale = ref(userPreferences.getLocale());

  watchEffect(() => {
    document.documentElement.lang = currentLocale.value;
  });

  watch(currentLocale, (newLocale) => {
    i18n.locale.value = newLocale;
    userPreferences.setLocale(newLocale);
  });

  const changeLocale = (newLocale: string) => {
    currentLocale.value = newLocale;
  };

  const getAvailableLocales = () => {
    return i18n.availableLocales;
  };

  return {
    currentLocale,
    changeLocale,
    getAvailableLocales,
  };
}

import { StorageService } from '@/services/storage-service.ts';
import { JsonObject, JsonValue } from '@/types/json-type.ts';
import { UserPreferences, UserPreferencesKeys } from '@/types/user-preferences.ts';

export class UserPreferencesService {
  private readonly storageKey = 'userPreferences';
  private static _instance?: UserPreferencesService;
  private storageService: StorageService;
  private preferences: UserPreferences;

  static getInstance(): UserPreferencesService {
    if (!UserPreferencesService._instance) {
      UserPreferencesService._instance = new UserPreferencesService();
    }

    return UserPreferencesService._instance;
  }

  private static getDefaultPreferences(): UserPreferences {
    const browserLocaleRaw = navigator.language;

    const browserLocale = browserLocaleRaw.split('-')[0];
    const defaultLocale = ['en', 'fr'].includes(browserLocale) ? browserLocale : 'en';

    return {
      [UserPreferencesKeys.LOCALE]: defaultLocale,
    };
  }

  private constructor() {
    this.preferences = UserPreferencesService.getDefaultPreferences();

    this.storageService = StorageService.getInstance();

    this.load();
  }

  load(): void {
    const storedPreferences = this.storageService.getItem(this.storageKey);
    if (storedPreferences) {
      this.preferences = JSON.parse(storedPreferences) as UserPreferences;
    }
  }

  private setAll(preferences: JsonObject): void {
    this.storageService.setItem(this.storageKey, JSON.stringify(preferences));
  }

  private set(key: UserPreferencesKeys, value: JsonValue): void {
    this.preferences[key] = value;
    this.setAll(this.preferences);
  }

  private get(key: UserPreferencesKeys): JsonValue {
    return this.preferences[key];
  }

  getLocale(): string {
    return this.get(UserPreferencesKeys.LOCALE) as string;
  }

  setLocale(locale: string): void {
    this.set(UserPreferencesKeys.LOCALE, locale);
  }
}

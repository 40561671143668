import { z } from 'zod';

import { WalletProduct } from '@/types/users/wallet-product-enum.ts';

export const WalletProductSchema = z.nativeEnum(WalletProduct);

export const WalletSchema = z.object({
  id: z.string(),
  userId: z.string().uuid(),
  product: WalletProductSchema,
  value: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type Wallet = z.infer<typeof WalletSchema>;

import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';
import { CreateKycFormData } from '@/models/kyc/create-kyc-form-data-type.ts';
import { CreateKycResponse } from '@/schemas/kyc/create-kyc-response-schema.ts';

export function useCreateKycMutation(
  onSuccess?: (response: CreateKycResponse) => void,
  onError?: (error: ApiError) => void,
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<CreateKycResponse, ApiError, CreateKycFormData>({
    mutationFn: async (form: CreateKycFormData) => {
      const response = await api.post<CreateKycResponse>('/kyc', form);

      return response.data;
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
      onSuccess?.(response);
    },
  });
}

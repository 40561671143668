import { RouteRecordRaw } from 'vue-router';

import NotFound from '@/pages/NotFound.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/asset',
    name: 'asset',
    component: () => import('@/pages/asset/index.vue'),
    redirect: '/asset/performance',
    children: [
      {
        path: 'performance',
        name: 'AssetPerformance',
        component: () => import('@/pages/asset/Performance.vue'),
      },
      {
        path: 'impact',
        name: 'AssetImpact',
        component: () => import('@/pages/asset/Impact.vue'),
      },
      {
        path: 'product-information',
        name: 'AssetProductInformation',
        component: () => import('@/pages/asset/ProductInformation.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/asset/performance',
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('@/pages/Portfolio.vue'),
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('@/pages/Resources.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/Settings.vue'),
    meta: { authRequired: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      layout: 'empty',
    },
  },
];

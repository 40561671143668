<script setup lang="ts">
import { useRouter } from 'vue-router';
import { toast } from 'vue-sonner';

import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useResetPasswordMutation } from '@/queries/auth/use-reset-password-mutation.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

const props = defineProps<{
  currentEmail: string;
}>();

defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const router = useRouter();

const onResetPasswordSuccess = () => {
  toast('A new code has been sent to your email');
};

const onResetPasswordError = (error: ApiError) => {
  toast.error(error.data.message);
};

const { mutate: resetPasswordMutation, isPending: isResetPasswordPending } =
  useResetPasswordMutation(onResetPasswordSuccess, onResetPasswordError);

const resetPassword = () => {
  resetPasswordMutation({ email: props.currentEmail });
};

const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = () => {
  changeModalState(ResetPasswordDialogState.sendEmail);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-2 py-4 pb-0 md:px-6 md:py-6">
    <div class="w-fit">
      <TIcon icon="arrowLeft" @click="goBack" />
    </div>
    <div class="w-fit">
      <TIcon icon="cross" @click="closeDialogAndRemoveQuery" />
    </div>
  </TDialogHeader>
  <div class="mt-20 flex justify-center">
    <div class="flex w-full max-w-[410px] flex-col gap-4 px-2 py-4">
      <h4 class="mb-4 font-bold">Check your inbox</h4>
      <div>
        <p class="break-all text-sm font-medium text-primary-700">
          An email has been sent to {{ currentEmail }}.
        </p>
      </div>
      <div class="mb-6 mt-6 flex items-start gap-3 rounded-lg border border-primary-300 p-4">
        <TIcon icon="infoEmpty" color="primary-800" />
        <div class="flex flex-1 flex-col gap-3">
          <p class="text-sm font-semibold text-primary-800">Didn’t receive an email?</p>
          <p class="text-sm font-medium text-primary-500">
            We can only send password reset instructions to registered emails with an existing
            account. If the email doesn’t appear in your inbox or spam folder, please check the
            email you entered or click below to send a new email.
          </p>
          <TButton
            class="w-fit"
            variant="outline"
            size="sm"
            :class="{ 'pointer-events-none': isResetPasswordPending }"
            @click="resetPassword"
          >
            <p v-if="!isResetPasswordPending">Resend email</p>
            <TWaveLoader v-else size="sm" />
          </TButton>
        </div>
      </div>
      <p class="mt-2 text-sm font-medium text-primary-600">
        Having trouble logging in?
        <a href="mailto:team@homaio.com" class="underline underline-offset-4">Contact support</a>
      </p>
    </div>
  </div>
</template>

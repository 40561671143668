<script setup lang="ts">
import { computed, watch, type Component } from 'vue';

import { TButton } from '@/components/ui/button';
import { TDialog, TDialogClose, TDialogContent, TDialogTrigger } from '@/components/ui/dialog';
import {
  TTooltip,
  TTooltipContent,
  TTooltipProvider,
  TTooltipTrigger,
} from '@/components/ui/tooltip';
import { useWithdrawDialog } from '@/hooks/invest/use-withdraw-dialog';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { WithdrawDialogState } from '@/types/invest/withdraw-dialog-state-enum';

import WithdrawDialogContentConfirmation from './WithdrawDialogContentConfirmation.vue';
import WithdrawDialogContentCreation from './WithdrawDialogContentCreation.vue';

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const props = defineProps<{
  disabled?: boolean;
}>();

const stateComponents: Record<WithdrawDialogState, Component> = {
  [WithdrawDialogState.Creation]: WithdrawDialogContentCreation,
  [WithdrawDialogState.Confirmation]: WithdrawDialogContentConfirmation,
};

const { data: user } = useUserMeQuery();

const { isOpen, currentState, closeDialog, changeModalState } = useWithdrawDialog();

const walletEUR = computed(() => {
  return user.value?.wallets?.[0]?.value || 0;
});

const hasNoFunds = computed(() => {
  return walletEUR.value === 0;
});

const setDefaultState = (isDialogOpen: boolean): void => {
  if (isDialogOpen) {
    changeModalState(WithdrawDialogState.Creation);
  }
};

watch(isOpen, (newValue) => {
  setDefaultState(newValue);
});
</script>

<template>
  <TDialog v-model:open="isOpen">
    <TTooltipProvider>
      <TTooltip :disabled="!hasNoFunds">
        <TTooltipTrigger as-child>
          <TDialogTrigger as-child>
            <TButton
              :disabled="hasNoFunds || props.disabled"
              variant="gray"
              size="fluid-default"
              @click="emits('click')"
            >
              {{ $t('layout.navBar.userDropdown.withdraw') }}
            </TButton>
          </TDialogTrigger>
        </TTooltipTrigger>
        <TTooltipContent>
          <p class="m-3 font-semibold leading-5">
            {{ $t('layout.navBar.userDropdown.withdrawNoFundsTooltip') }}
          </p>
        </TTooltipContent>
      </TTooltip>
    </TTooltipProvider>
    <TDialogContent
      class="h-full max-h-[700px] max-w-[1200px] grid-rows-[auto_minmax(0,1fr)_auto] gap-0 bg-beige-100 p-0"
      @interact-outside="closeDialog"
    >
      <template #modal-close>
        <TDialogClose />
      </template>
      <component :is="stateComponents[currentState]" v-if="isOpen" />
    </TDialogContent>
  </TDialog>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { z } from 'zod';

import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormItem, FormMessage, FormField } from '@/components/ui/form';
import { TIcon } from '@/components/ui/icon';
import TInput from '@/components/ui/input/TInput.vue';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useResetPasswordMutation } from '@/queries/auth/use-reset-password-mutation.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

defineProps<{
  currentEmail: string;
}>();

const emits = defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const zodSchema = z.object({
  email: z.string().email(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const router = useRouter();

const onSubmit = handleSubmit((formValues) => {
  resetPassword(formValues.email);
});

const onResetPasswordSuccess = () => {
  emits('update:email', values.email);
  changeModalState(ResetPasswordDialogState.waitForEmail);
};

const onResetPasswordError = (error: ApiError) => {
  errorMessage.value = error.data.message;
};

const { mutate: resetPasswordMutation, isPending: isresetPasswordPending } =
  useResetPasswordMutation(onResetPasswordSuccess, onResetPasswordError);

const resetPassword = (email: string) => {
  resetPasswordMutation({ email });
};

const { openDialog } = useAuthDialog();
const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = () => {
  closeDialog();
  openDialog(AuthDialogState.Login);
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-2 py-4 pb-0 md:px-6 md:py-6">
    <div class="w-fit">
      <TIcon icon="arrowLeft" @click="goBack" />
    </div>
    <div class="w-fit">
      <TIcon icon="cross" @click="closeDialogAndRemoveQuery" />
    </div>
  </TDialogHeader>
  <div class="mt-20 flex justify-center">
    <div class="flex w-full max-w-[410px] flex-col gap-4 px-2 py-4">
      <h4 class="mb-4 font-bold">Enter your email address</h4>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormControl>
              <TInput type="email" placeholder="Email" v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader v-if="isresetPasswordPending" size="sm" class="bg-white" />
          <p v-else>Reset password</p>
        </TButton>
      </form>
      <p class="mt-2 text-sm font-medium text-primary-600">
        Having trouble logging in?
        <a href="mailto:team@homaio.com" class="underline underline-offset-4">Contact support</a>
      </p>
    </div>
  </div>
</template>

import { App } from 'vue';

import { DI_ANALYTICS_SERVICE } from '@/constants/injection-constants.ts';
import AnalyticsService from '@/services/analytics/analytics-service.ts';
import { PosthogAnalytics } from '@/services/analytics/posthog-handler.ts';

export default {
  install(app: App): void {
    const analytics = new AnalyticsService([new PosthogAnalytics()]);

    analytics.install(app);

    app.provide(DI_ANALYTICS_SERVICE, analytics);
  },
};

<script setup lang="ts">
import { computed, ref } from 'vue';

import LayoutNavBarUserDropdownContent from '@/components/layout/LayoutNavBarUserDropdownContent.vue';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { TButton } from '@/components/ui/button';
import { TPopover, TPopoverContent, TPopoverTrigger } from '@/components/ui/popover';
import { formatCurrency } from '@/lib/number-helpers.ts';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';

const { data: user } = useUserMeQuery();

const initials = computed(() => {
  if (!user.value) {
    return '';
  }

  if (user.value.kyc) {
    return `${user.value.kyc.firstName.charAt(0)}${user.value.kyc.lastName.charAt(0)}`.toUpperCase();
  }

  if (user.value.email) {
    return `${user.value.email.charAt(0)}`.toUpperCase();
  }

  return '';
});

const userName = computed(() => {
  if (!user.value) {
    return '';
  }

  if (user.value.kyc) {
    return `${user.value.kyc.firstName} ${user.value.kyc.lastName}`;
  }

  if (user.value.email) {
    return `${user.value.email}`;
  }

  return '';
});

const walletEUR = computed(() => {
  return user.value?.wallets?.[0]?.value || 0;
});

const closeMenu = (): void => {
  isOpen.value = false;
};

const isOpen = ref(false);
</script>

<template>
  <TPopover :open="isOpen">
    <TPopoverTrigger as-child>
      <TButton
        variant="gray"
        class="flex items-center justify-between bg-beige-100 p-1"
        @click="isOpen = true"
      >
        <p class="px-3 py-2 font-sans text-base font-medium text-primary-800">
          {{ formatCurrency(walletEUR, 'EUR') }}
          {{ $t('layout.navBar.userDropdown.available') }}
        </p>
        <div class="flex max-w-96 items-center justify-center gap-2 rounded-md bg-white px-2 py-1">
          <Avatar class="h-[24px] w-[24px] bg-primary-700 text-white">
            <AvatarFallback> {{ initials }}</AvatarFallback>
          </Avatar>
          <span class="truncate">{{ userName }}</span>
        </div>
      </TButton>
    </TPopoverTrigger>
    <TPopoverContent class="mt-6 w-[330px] p-4" @interact-outside="closeMenu">
      <LayoutNavBarUserDropdownContent @close="closeMenu" />
    </TPopoverContent>
  </TPopover>
</template>

import { onUnmounted } from 'vue';

import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api.ts';

export function useLogoutInterceptor(): void {
  const api = useApi();
  const { logout, isAuthenticated } = useAuth();

  const ejectInterceptor = api.registerResponseInterceptor(
    (response) => response,
    async (error) => {
      if (error.status === 401 && isAuthenticated.value) {
        await logout();
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );

  onUnmounted(() => {
    ejectInterceptor();
  });
}

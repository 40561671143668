<script setup lang="ts">
import { X } from 'lucide-vue-next';
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '@/lib/utils';

const props = withDefaults(
  defineProps<
    DialogContentProps & {
      class?: HTMLAttributes['class'];
      position?: 'center' | 'bottom' | 'left' | 'right';
    }
  >(),
  {
    position: 'center',
    class: '',
  },
);
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const positionClasses = computed(() => {
  const classes = {
    center:
      'left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
    bottom:
      'bottom-0 left-0 right-0 data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full sm:rounded-lg',
    left: 'left-0 top-0 h-full md:left-2 md:inset-y-2 md:h-[calc(100vh-16px)] sm:w-[538px] md:w-2/5 md:min-w-[517px] lg:left-4 lg:inset-y-4 lg:h-[calc(100vh-32px)] data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full rounded-none md:rounded-xl xl:rounded-2xl',
    right:
      'right-0 top-0 h-full md:right-2 md:inset-y-2 md:h-[calc(100vh-16px)] sm:w-[538px] md:w-2/5 md:min-w-[517px] lg:right-4 lg:inset-y-4 lg:h-[calc(100vh-32px)] data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full rounded-none md:rounded-xl xl:rounded-2xl',
  };

  return classes[props.position];
});
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />
    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'fixed z-50 grid w-full gap-4 border border-slate-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 dark:border-slate-800 dark:bg-slate-950',
          props.class,
          positionClasses,
        )
      "
    >
      <slot />

      <slot name="modal-close">
        <DialogClose
          class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400"
        >
          <X class="h-4 w-4" />
          <span class="sr-only">Close</span>
        </DialogClose>
      </slot>
    </DialogContent>
  </DialogPortal>
</template>

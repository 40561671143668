<script setup lang="ts">
import { TButton } from '@/components/ui/button';
import { TIcon } from '@/components/ui/icon';

const emit = defineEmits(['click']);
</script>

<template>
  <TButton
    variant="ghost"
    size="fluid-default"
    class="flex items-center gap-2"
    @click="emit('click')"
  >
    <TIcon icon="arrowLeft" color="primary-500" size="sm" />
    {{ $t('common.back') }}
  </TButton>
</template>

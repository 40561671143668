import { App } from 'vue';

import { DI_API_CLIENT } from '@/constants/injection-constants.ts';
import { Api } from '@/services/api-client.ts';

export default {
  install(app: App): void {
    const api = new Api();

    app.provide(DI_API_CLIENT, api);
  },
};

import { z } from 'zod';

import { TransferStatus } from '@/types/users/transfer-status-enum.ts';
import { TransferType } from '@/types/users/transfer-type-enum.ts';

export const TransferTypeSchema = z.nativeEnum(TransferType);
export const TransferStatusSchema = z.nativeEnum(TransferStatus);

export const TransferSchema = z.object({
  id: z.string(),
  userId: z.string().uuid(),
  type: TransferTypeSchema,
  status: TransferStatusSchema,
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type Transfer = z.infer<typeof TransferSchema>;

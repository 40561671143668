<script setup lang="ts">
import { Check } from 'lucide-vue-next';
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue';
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<CheckboxRootProps & { class?: HTMLAttributes['class'] }>();
const emits = defineEmits<CheckboxRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <CheckboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'peer h-4 w-4 shrink-0 rounded border border-primary-300' +
          ' ring-offset-white focus-visible:outline-none focus-visible:ring-2' +
          ' focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed' +
          ' disabled:opacity-50 data-[state=checked]:bg-primary-900 data-[state=checked]:text-slate-50' +
          ' dark:border-slate-50 dark:border-slate-800 dark:ring-offset-slate-950' +
          'dark:focus-visible:ring-slate-300 dark:data-[state=checked]:bg-slate-50' +
          ' dark:data-[state=checked]:text-slate-900',
        props.class,
      )
    "
  >
    <CheckboxIndicator class="flex h-full w-full items-center justify-center text-current">
      <slot>
        <Check class="h-4 w-4" />
      </slot>
    </CheckboxIndicator>
  </CheckboxRoot>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { toast } from 'vue-sonner';

import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TIcon } from '@/components/ui/icon';
import { TPinInput, TPinInputGroup, TPinInputInput } from '@/components/ui/pin-input';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useSendVerifyEmailMutation } from '@/queries/auth/use-send-verify-email-mutation.ts';
import { useVerifyEmailCodeMutation } from '@/queries/auth/use-verify-email-code-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { kycData, updateLastEmailCodeGeneratedAt } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const errorMessage = ref<string | null>('');

const onSuccessResend = (): void => {
  updateLastEmailCodeGeneratedAt(new Date());
  toast('A new code has been sent to your email');
};

const onErrorResend = (error: ApiError): void => {
  toast.error(error.data.message);
};

const { mutate: sendVerifyEmailMutation, isPending: isSendVerifyEmailPending } =
  useSendVerifyEmailMutation(onSuccessResend, onErrorResend);

const onSuccess = (): void => {
  kycData.value.data.isEmailVerified = true;
  changeModalState(KycDialogState.LegalForm);
};

const onError = (error: ApiError): void => {
  errorMessage.value = error.data.message;
};

const { mutate: verifyEmailMutation, isPending: verifyEmailPending } = useVerifyEmailCodeMutation(
  onSuccess,
  onError,
);

const value = ref<string[]>([]);

const handleComplete = (_: string[]) => {
  verifyCode();
};

const verifyCode = () => {
  verifyEmailMutation(value.value.join(''));
};

watch(value, () => {
  errorMessage.value = null;
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-6 py-4 pb-0">
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="mt-8 flex justify-center">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-2 overflow-scroll px-2 py-4">
      <h4 class="font-bold">{{ $t('dialog.kyc.verify.title') }}</h4>
      <div>
        <p class="mb-6 mt-6 text-sm font-medium text-primary-700">
          {{ $t('dialog.kyc.verify.description', { email: kycData.data.email }) }}
        </p>
      </div>
      <div class="mb-6">
        <TPinInput id="pin-input" v-model="value" placeholder="0" @complete="handleComplete">
          <TPinInputGroup class="flex-1 gap-1">
            <TPinInputInput
              v-for="(id, index) in 6"
              :key="id"
              class="h-14 flex-1 rounded-md border text-lg font-medium placeholder:text-primary-400"
              :class="{ 'border-danger-500 bg-danger-100 text-danger-600': errorMessage }"
              :index="index"
            />
          </TPinInputGroup>
        </TPinInput>
      </div>
      <div v-if="errorMessage" class="mb-6 flex justify-start gap-2">
        <TIcon icon="hexaWarningOutline" color="danger-500" size="sm" />
        <p class="flex-1 text-sm font-medium text-danger-500">
          {{ errorMessage }}
        </p>
      </div>
      <div class="mb-6 flex items-start gap-3 rounded-lg border border-primary-300 p-4">
        <TIcon icon="infoEmpty" color="primary-800" />
        <div class="flex flex-1 flex-col gap-3">
          <p class="text-sm font-semibold text-primary-800">
            {{ $t('dialog.kyc.verify.calloutTitle') }}
          </p>
          <p class="text-sm font-medium text-primary-500">
            {{ $t('dialog.kyc.verify.calloutDescription') }}
          </p>
          <TButton
            class="w-fit"
            variant="outline"
            size="sm"
            :class="{ 'pointer-events-none': isSendVerifyEmailPending }"
            @click="sendVerifyEmailMutation"
          >
            <p v-if="!isSendVerifyEmailPending">{{ $t('dialog.kyc.verify.resend') }}</p>
            <TWaveLoader v-else size="sm" />
          </TButton>
        </div>
      </div>
      <TButton :disabled="verifyEmailPending || value.length !== 6" @click="verifyCode">
        <p v-if="!verifyEmailPending">{{ $t('common.continue') }}</p>
        <TWaveLoader v-else size="sm" class="bg-white" />
      </TButton>
    </div>
  </div>
</template>

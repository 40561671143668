<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue-sonner';

import KycDialogBackBtn from '@/components/kyc/KycDialogBackBtn.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { TDragDropUploader } from '@/components/ui/drag-drop-uploader';
import { TIcon } from '@/components/ui/icon';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import { useApi } from '@/hooks/use-api.ts';
import ApiError from '@/models/api/api-error.ts';
import { useFinishKycMutation } from '@/queries/kyc/use-finish-kyc-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { t: $t } = useI18n();

const api = useApi();

const fileLoading = ref(false);
const fileUploaded = ref(false);
const fileUploadUrl = ref('');
const verifyLoading = ref(false);

const isButtonDisabled = computed(() => !fileUploadUrl.value);

const { kycData } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const queryClient = useQueryClient();

const goBack = (): void => {
  changeModalState(KycDialogState.BankInformation);
};

const goNext = (): void => {
  changeModalState(KycDialogState.ThankYou);
};

const registrationDocument = ref<File | null>(null);

const removeRegistrationDocument = () => {
  registrationDocument.value = null;
  fileUploaded.value = false;
  fileUploadUrl.value = '';
};

const uploadFileToAws = async (url: string, file: File) => {
  try {
    await api.uploadFile(url, file);

    fileUploaded.value = true;
  } catch (error) {
    console.error(error);
    registrationDocument.value = null;

    toast.error($t('dialog.kyc.registrationDocument.uploadError'));
  }
};

const uploadDocuments = async () => {
  try {
    verifyLoading.value = true;
    await uploadFileToAws(fileUploadUrl.value, registrationDocument.value as File);
    await verifyDocuments();
  } catch (error) {
    console.error(error);

    toast.error($t('dialog.kyc.registrationDocument.uploadError'));
  }

  verifyLoading.value = false;
};

const verifyDocuments = async () => {
  verifyLoading.value = true;
  await api.get<void>(
    `/kyc/${kycData.value.data.id}/document/verify?filename=${registrationDocument.value?.name}`,
  );

  finishKyc();
};

watch(registrationDocument, async (newDocument) => {
  if (newDocument) {
    fileLoading.value = true;

    const response = await api.get<string>(`/kyc/${kycData.value.data.id}/document/upload`, {
      filename: newDocument.name,
    });

    fileLoading.value = false;

    fileUploadUrl.value = response.data;
  }
});

const onKycDoneSuccess = async (): Promise<void> => {
  await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
  verifyLoading.value = false;
  goNext();
};

const onKycDoneError = (_: ApiError): void => {
  verifyLoading.value = false;
  toast.error($t('common.genericError'));
};

const { mutate: finishKycMutation } = useFinishKycMutation(onKycDoneSuccess, onKycDoneError);

const finishKyc = (): void => {
  finishKycMutation(kycData.value.data.id);
};
</script>

<template>
  <TDialogHeader
    class="absolute left-0 top-0 flex w-full flex-row justify-between px-1 py-4 pb-0 md:px-6"
  >
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="no-scrollbar mt-12 flex justify-center overflow-scroll md:mt-0">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-8 overflow-scroll px-2 py-4">
      <div class="mt-20">
        <KycDialogBackBtn class="w-fit" @click="goBack" />
        <h4 class="font-bold">{{ $t('dialog.kyc.registrationDocument.title') }}</h4>
      </div>
      <div class="flex flex-col gap-4">
        <p class="mb-6 text-sm font-medium text-primary-700">
          {{ $t('dialog.kyc.registrationDocument.description') }}
        </p>
        <TDragDropUploader
          v-model="registrationDocument"
          file-accept="image/png, image/jpeg, image/jpg, application/pdf"
        >
          <template #message>
            <div
              class="flex h-40 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-center text-sm font-medium text-primary-500"
            >
              <TIcon icon="upload" color="primary-700" size="md" />
              <p class="mt-4 font-semibold text-primary-700">
                {{ $t('dialog.kyc.registrationDocument.uploadTitle') }}
              </p>
              <p class="text-xs font-semibold text-primary-700">
                {{ $t('dialog.kyc.registrationDocument.uploadDescription') }}
              </p>
              <p class="mt-4 text-xs font-medium text-primary-500">
                {{ $t('dialog.kyc.registrationDocument.uploadNotice') }}
              </p>
            </div>
          </template>
          <template #preview="{ currentFile }">
            <div
              class="flex h-40 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.5px] border-dashed border-primary-400 bg-white px-3 text-center text-sm font-medium text-primary-500"
            >
              <div
                v-if="!fileLoading"
                class="flex h-full w-full flex-col items-center justify-center text-center"
              >
                <TIcon icon="emptyPage" color="primary-700" size="md" />
                <p class="mt-4 line-clamp-2 font-semibold text-primary-700">
                  {{ (currentFile as File)?.name }}
                </p>
                <p
                  class="mt-4 text-xs font-medium text-primary-500"
                  @click="removeRegistrationDocument"
                >
                  {{ $t('dialog.kyc.registrationDocument.removeFile') }}
                </p>
              </div>
              <div v-else class="flex h-full w-full flex-col items-center justify-center">
                <TWaveLoader size="sm" />
              </div>
            </div>
          </template>
        </TDragDropUploader>
        <TButton class="mt-6" :disabled="isButtonDisabled" @click="uploadDocuments">
          <TWaveLoader v-if="verifyLoading" size="sm" class="bg-white" />
          <p v-else>{{ $t('common.next') }}</p>
        </TButton>
      </div>
    </div>
  </div>
</template>

export const PASSWORD_REGEX = {
  minLengthRegex: /^.{8,}$/,
  upperCaseRegex: /[A-Z]/,
  lowerCaseRegex: /[a-z]/,
  numberRegex: /\d/,
  specialCharRegex: /[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/,
};

export const PASSWORD_RULES = [
  {
    rule: (values: any) => PASSWORD_REGEX.upperCaseRegex.test(values.password ?? ''),
    message: '1 uppercase letter',
  },
  {
    rule: (values: any) => PASSWORD_REGEX.lowerCaseRegex.test(values.password ?? ''),
    message: '1 lowercase letter',
  },
  {
    rule: (values: any) => PASSWORD_REGEX.specialCharRegex.test(values.password ?? ''),
    message: '1 special character',
  },
  {
    rule: (values: any) => PASSWORD_REGEX.numberRegex.test(values.password ?? ''),
    message: '1 number',
  },
  {
    rule: (values: any) => PASSWORD_REGEX.minLengthRegex.test(values.password ?? ''),
    message: 'At least 8 characters',
  },
];

import { ref, Ref } from 'vue';

import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const isOpen: Ref<boolean> = ref(false);
const currentState: Ref<KycDialogState> = ref(KycDialogState.Intro);

export function useKycDialog() {
  const changeModalState = (state: KycDialogState) => {
    currentState.value = state;
  };

  const openDialog = (state: KycDialogState) => {
    isOpen.value = true;
    currentState.value = state;
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  return {
    openDialog,
    closeDialog,
    changeModalState,
    isOpen,
    currentState,
  };
}

import * as Sentry from '@sentry/vue';

import { User } from '@/schemas/users/user-schema.ts';

export function captureException(error: Error, context?: Record<string, any>): void {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    Sentry.captureException(error, context);
  } else {
    console.error(error);
  }
}

export function captureMessage(message: string, context?: Record<string, any>): void {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    Sentry.captureMessage(message, context);
  } else {
    console.error(message);
  }
}

export function setSentryUser(user: User): void {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  }
}

export function clearSentryUser(): void {
  if (import.meta.env.VITE_APP_ENV === 'production') {
    Sentry.setUser(null);
  }
}
